<template>
    <div class="emc-home-index" :class="skin">
        <div class="toolbar">
            <div class="mobile" v-if="showMobileClient === '1'" @click="openMobileApp">
                <img src="../component/img/mobile.svg" alt="mobile" />
                {{ $i18n('scanQR.mobile.client') }}
            </div>
            <div v-if="i18n" class="language-switch" @click="languageSwitch">
                <div class="language zh" :class="{ active: locale === 'zh-cn' }">中</div>
                <div class="language en" :class="{ active: locale === 'en-US' }">En</div>
            </div>
        </div>
        <div class="main">
            <div class="bg">
                <div class="sys-name">
                    <div class="title" v-if="system" v-html="propI18N(system)"></div>
                    <div class="title" v-else>
                        云服务
                        <span class="dot"></span>云端 <span class="dot"></span>实验室信息管理系统
                    </div>
                    <div class="sub-title" v-if="description" v-html="description"></div>
                    <div class="sub-title" v-else>Laboratory Information Management System (SW-LIMS V12)</div>
                </div>
            </div>
            <div class="login-container" v-if="routePath !== 'resetPassword'">
                <header class="logo-container" :style="{ backgroundImage: logo ? `url(${logo})` : null }"></header>
                <section class="login-form">
                    <div class="error-container" v-if="errorMessage">
                        <div class="error">
                            <span class="error-icon">
                                <infoImg v-bind="{ width: '16px', height: '16px' }" />
                            </span>
                            {{ errorMessage }}
                        </div>
                    </div>
                    <div class="login-mask" v-if="requesting"></div>
                    <div class="input-group" name="user">
                        <span class="input-group-addon user">
                            <userImg v-bind="{ color: colorValue, width: '16px', height: '16px' }" />
                        </span>
                        <input
                            :placeholder="$i18n('input.username')"
                            v-model="user.name"
                            @change="getDeptAndRole"
                            @focus="showUserNames"
                            @click.stop="showUserNames"
                            @blur="hidePicker"
                        />
                    </div>
                    <div class="input-group" name="pw">
                        <span class="input-group-addon lock">
                            <lockImg v-bind="{ color: colorValue, width: '16px', height: '16px' }" />
                        </span>
                        <passwordInput
                            ref="password"
                            :placeholder="$i18n('input.password')"
                            :options="{ remembered: remembered }"
                            @enterKeyDown="pawEnterHandle"
                            @change="passowordChangeHandle"
                        ></passwordInput>
                    </div>
                    <div class="input-group">
                        <span class="input-group-addon">
                            <deptImg v-bind="{ color: colorValue, width: '16px', height: '16px' }" />
                        </span>
                        <select-field
                            ref="dept"
                            :options="{ placeholder: $i18n('input.dept'), field: 'deptId', items: [] }"
                            @change="changeHandle"
                        ></select-field>
                    </div>
                    <div class="input-group">
                        <span class="input-group-addon user">
                            <roleImg v-bind="{ color: colorValue, width: '16px', height: '16px' }" />
                        </span>
                        <select-field
                            ref="role"
                            :options="{ placeholder: $i18n('input.role'), field: 'roleId', items: [] }"
                            @change="changeHandle"
                        ></select-field>
                    </div>
                </section>
                <div class="entry-input-tip">
                    <span @click.stop="passwordRecovery" v-if="retrievePassword === '1'">
                        {{ $i18n('password.forget.text') }}
                    </span>
                </div>
                <footer>
                    <div class="login-btn" :class="{ requesting: requesting }" v-on:click="login" tabindex="0">
                        {{ $i18n('button.login') }}
                    </div>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Gikam from '@/gikam/js/core/gikam-core';
import infoImg from '@/gikam/js/components/template/img/info.vue';
import userImg from '@/gikam/js/components/template/img/user.vue';
import lockImg from '@/gikam/js/components/template/img/lock.vue';
import deptImg from '@/gikam/js/components/template/img/dept.vue';
import roleImg from '@/gikam/js/components/template/img/role.vue';
import selectField from '@/gikam/js/components/template/fields/select/select';
import passwordInput from '@/gikam/js/components/template/fields/password/passwordInput';
import { LoginUtils } from '@/gikam/js/utils/loginUtils';
import { I18N } from '@/gikam/i18n/I18N.js';

export default {
    props: {
        retrievePassword: {
            type: String,
            default: '0'
        },
        routePath: {
            type: String,
            default: ''
        },
        logo: {
            type: String,
            default: null
        },
        skin: {
            type: String,
            default: 'blue'
        },
        system: String,
        description: String,
        userRemember: Boolean,
        remembered: Boolean,
        locale: String,
        i18n: Boolean,
        showMobileClient: String
    },

    components: {
        infoImg,
        userImg,
        lockImg,
        selectField,
        deptImg,
        roleImg,
        passwordInput
    },

    data() {
        return {
            errorMessage: null,
            language: 'zh',
            requesting: false,
            user: {
                name: null,
                password: null,
                roleId: null,
                deptId: null
            },
            requestedDept: false,
            requestedRole: false
        };
    },

    computed: {
        colorValue() {
            const color = { blue: '#007aff', green: '#27986d' };
            return color[this.skin];
        }
    },

    methods: {
        // 密码回车事件
        pawEnterHandle(data) {
            this.user.password = data;
            this.$refs.password.dumpActiveCell();
        },

        languageSwitch() {
            I18N.setLocale(this.locale === 'zh-cn' ? 'en-US' : 'zh-cn');
        },

        validate() {
            if (!this.user.name) {
                this.errorMessage = this.$i18n('input.username');
                return false;
            }
            if (!this.user.password) {
                this.errorMessage = this.$i18n('input.password');
                return false;
            }
            if (!this.user.deptId) {
                this.errorMessage = this.$i18n('input.dept');
                return false;
            }
            if (!this.user.roleId) {
                this.errorMessage = this.$i18n('input.role');
                return false;
            }
            this.errorMessage = null;
            return true;
        },

        changeHandle: function(field, value) {
            Gikam.setFieldValue(this.user, field, value);
        },

        async login() {
            if (this.requestedRole === false || this.requestedDept === false) {
                return;
            }
            if (this.validate() === false) {
                return;
            }
            try {
                this.requesting = true;
                await LoginUtils.login(this.user.name, this.user.password, this.user.deptId, this.user.roleId);
                if (this.userRemember) {
                    let sunwayNames = JSON.parse(window.localStorage.getItem('sunwayNames')) || [];
                    sunwayNames.indexOf(this.user.name) === -1 && sunwayNames.push(this.user.name.trim());
                    window.localStorage.setItem('sunwayNames', JSON.stringify(sunwayNames));
                }
            } catch (error) {
                this.errorMessage = error;
            } finally {
                this.requesting = false;
            }
        },

        clean: function() {
            this.$refs.dept.clean();
            this.$refs.role.clean();
        },

        async getDeptAndRole() {
            this.requestedDept = false;
            this.requestedRole = false;
            // if (this.requestedRole === true && this.requestedDept === true) {
            //     return;
            // }
            if (!this.user.name || !this.user.password) {
                return;
            }
            this.clean();
            try {
                const publicKey = await LoginUtils.checkAccount(this.user.name, this.user.password);
                this.getDeptOptions(publicKey);
                this.getRoleOptions(publicKey);
                this.errorMessage = null;
            } catch (error) {
                this.errorMessage = error;
            }
        },

        async getDeptOptions(publicKey) {
            const deptList = await LoginUtils.getDeptList(this.user.name, this.user.password, publicKey);
            const { items, defaultValue } = LoginUtils.getDefaultDeptItem(deptList);
            this.$refs.dept.items = items;
            this.$refs.dept.value = defaultValue;
            this.requestedDept = true;
        },

        async getRoleOptions(publicKey) {
            const roleList = await LoginUtils.getRoleList(this.user.name, this.user.password, publicKey);
            const { items, defaultValue } = LoginUtils.getDefaultRoleItem(roleList);
            this.$refs.role.items = items;
            this.$refs.role.value = defaultValue;
            this.requestedRole = true;
        },

        passowordChangeHandle(filed, value) {
            this.user.password = value;
            this.getDeptAndRole();
        },

        //显示本地存储过的用户名
        showUserNames(e) {
            if (!this.userRemember) return;
            if (Gikam.userPicker) return;
            const sunwayNames = JSON.parse(window.localStorage.getItem('sunwayNames'));
            if (Gikam.isEmpty(sunwayNames)) return;
            const _this = this;
            Gikam.userPicker = new Vue({
                el: Gikam.createDom('div', document.body),
                components: {
                    userPicker: () => import('../component/userPicker')
                },
                computed: {
                    userNames() {
                        if (!_this.user.name) {
                            return sunwayNames;
                        }
                        return sunwayNames.filter(user => user.indexOf(_this.user.name) > -1);
                    }
                },
                render() {
                    return (
                        <user-picker
                            targetDom={e.target}
                            userNames={this.userNames}
                            onenterName={name => (_this.user.name = name)}
                        />
                    );
                }
            });
        },

        //隐藏用户名提示框
        hidePicker() {
            setTimeout(() => {
                if (!Gikam.userPicker) return;
                Gikam.userPicker.$destroy();
                Gikam.removeDom(Gikam.userPicker.$el);
                Gikam.userPicker = null;
            }, 5e2);
        },

        propI18N(text) {
            return Gikam.propI18N(text);
        },

        openMobileApp() {
            const url = window.location.href + '?mobile=app';
            window.open(url);
        },

        passwordRecovery() {
            window.open(Gikam.IFM_CONTEXT + '/#passwordRecovery');
        }
    },

    mounted() {
        document.addEventListener('keydown', event => {
            event.keyCode === 13 && this.login();
        });
    }
};
</script>

<style scoped>
input:focus {
    outline: none;
}

.main {
    height: 384px;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    top: 50%;
    margin-top: -199px;
}

.bg {
    width: 100%;
    background-color: #0a4196;
    height: 256px;
    display: flex;
    align-items: center;
}

.login-container {
    width: 412px;
    height: 398px;
    z-index: 1;
    background-color: #fff;
    right: 18%;
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
    top: 0;
}

.logo-container {
    height: 80px;
    background-image: url('../../../img/logo-blue.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
}

.login-form {
    width: 295px;
    margin: auto auto;
    padding-bottom: 10px;
    padding-top: 10px;
}

.login-form .input-group {
    height: 48px;
    display: flex;
}

.login-form .input-group input {
    min-width: 150px;
}

.login-form .input-group .input-group-addon {
    display: block;
    width: 50px;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.entry-input-tip {
    width: 295px;
    margin: 0 auto;
    height: 20px;
    line-height: 20px;
    text-align: right;
}

.entry-input-tip span {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #007aff;
    opacity: 0.85;
    cursor: pointer;
}

.entry-input-tip span:hover {
    opacity: 1;
}

.login-form input:not([type='checkbox']) {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    flex: 1;
    font-size: 14px;
    color: #666;
    padding-left: 50px;
    border-bottom: 1px solid #d9d9d9;
}

.login-form input:not([type='checkbox']):focus {
    border-color: rgba(0, 122, 255, 0.5);
}

input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.15) !important;
    font-size: 14px !important;
}

.auto-login-container input[type='checkbox'] {
    background-color: #fff;
    margin: 0 5px 0 0;
}

.login-form .quick-login {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: rgba(233, 188, 188, 0.45);
    padding: 0;
}

.login-form .quick-login .auto-login-container {
    display: flex;
    align-items: center;
    color: rgba(51, 51, 51, 0.45);
}

.login-form .quick-login .retrieve-pass {
    color: #007aff;
}

.login-btn {
    width: 264px;
    background-color: #0a4196;
    height: 36px;
    border-radius: 4px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    font-size: 12px;
    cursor: pointer;
    outline: none;
}

.login-btn.requesting {
    background-color: rgba(0, 0, 0, 0.2) !important;
    pointer-events: none;
}

.sys-name {
    padding-left: 96px;
}

.sys-name .title {
    font-size: 30px;
    color: #fff;
    display: flex;
    align-items: center;
}

.sys-name .sub-title {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.3);
    margin-top: 24px;
}

.dot {
    background-color: #fff;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 0 5px;
}

.login-form .select {
    border-bottom: 1px solid #d9d9d9;
}

.login-form .select.active {
    border-bottom-color: rgba(0, 122, 255, 0.5);
}

.error-container {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    color: red;
}

.error-container .error {
    height: 25px;
    width: 284px;
    background-color: rgba(255, 201, 201, 0.2);
    border: 1px solid #ff6e6e;
    color: #ff6e6e;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
}

.error-container .error .error-icon {
    display: block;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
}

.combo-box-group .input-group-addon {
    margin-right: -50px;
}

.select-picker {
    margin-top: 2px;
}

.login-mask {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
}

/* 换肤-绿色 */
.emc-home-index.green .language-switch > .language.active {
    color: #fff;
}

.emc-home-index.green .language-switch > .language.active,
.emc-home-index.green .bg,
.emc-home-index.green .login-btn {
    background-color: #27986d;
}

.input-group >>> .select > .select-container {
    border: none;
    padding-left: 50px;
    background-color: transparent;
}

.input-group >>> .select > .select-container > .placeholder {
    padding-left: 0;
    font-size: 14px;
}

.input-group >>> .select > .select-container > .text {
    padding-left: 0;
}

.login-container footer {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
}

.login-form .input-group[name='pw'] >>> input {
    padding-left: 50px;
    border: none;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
}

.login-form .input-group[name='pw'] >>> input:focus {
    border-color: rgba(0, 122, 255, 0.5);
}

/* 移动端适配 */
@media screen and (max-width: 1200px) and (min-width: 1000px) {
    .sys-name .title {
        font-size: 18px;
    }
}

@media screen and (max-width: 1000px) {
    .login-container {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
    }
    .sys-name {
        width: 100%;
        padding: 0;
        position: absolute;
        bottom: -200px;
    }
    .sys-name .title {
        font-size: 20px;
        color: #d9d9d9;
        display: flex;
        justify-content: center;
    }
    .sys-name .sub-title {
        color: #d9d9d9;
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 420px) {
    .main {
        width: 100%;
    }

    .bg {
        display: none;
    }

    .login-container {
        width: 100%;
        padding: 0 16px;
        box-shadow: none;
    }

    .sys-name {
        display: none;
    }

    .login-btn {
        width: 284px;
        font-size: 16px;
        background-color: #007aff;
    }

    .logo-container {
        background-size: 50%;
    }

    /*placeholder样式*/
    /*placeholder样式*/
    .login-form input::-webkit-input-placeholder,
    .login-form >>> input::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.85);
    }

    .login-form input::-moz-placeholder,
    .login-form >>> input::-moz-placeholder {
        color: rgba(0, 0, 0, 0.85);
    }

    .login-form input:-moz-placeholder,
    .login-form >>> input:-moz-placeholder {
        color: rgba(0, 0, 0, 0.85);
    }

    .login-form input::-ms-input-placeholder,
    .login-form >>> input::-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.85);
    }

    .login-form .quick-login .auto-login-container {
        color: rgba(0, 0, 0, 0.65);
    }
}

::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.15);
    font-size: 14px;
}
:-moz-placeholder {
    color: rgba(0, 0, 0, 0.15);
    font-size: 14px;
}
::-moz-placeholder {
    color: rgba(0, 0, 0, 0.15);
    font-size: 14px;
}
:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.15);
    font-size: 14px;
}
.select .select-container .placeholder {
    color: rgba(0, 0, 0, 0.1);
    font-size: 14px;
}
</style>
